import React, { useEffect, useRef } from 'react'
import mermaid from 'mermaid'

const MermaidChart = ({ chart }) => {
  const mermaidContainer = useRef(null)

  useEffect(() => {
    mermaid.initialize({ startOnLoad: true })

    if (mermaidContainer.current) {
      mermaid.contentLoaded()
    }
  }, [chart])

  return (
    <div ref={mermaidContainer} className="mermaid">
      {chart}
    </div>
  )
}

export default MermaidChart
